import React from 'react';
import './assets/style/bundle.scss';
import Routes from './routes';

function App() {
  return (
    <Routes />
  );
}

export default App;
